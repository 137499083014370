import { Attribute, Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { type FeatureFlagConfigKeys, FeatureFlagService } from './feature-flag.service';

/**
 * Директива для условного отображения или скрытия элементов на основе флага функции.
 *
 * Использование:
 * - Добавьте директиву к элементу, который вы хотите условно показать или скрыть:
 *   <div appFeatureFlag="featureName">...</div>
 *
 *   где "featureName" - это название флага функции для проверки.
 *
 * Функции управляются через FeatureFlagService.
 */
@Directive({ selector: '[appFeatureFlag]', standalone: true })
export class FeatureFlagDirective {
  /**
   * Ключи конфигурации для функции.
   * @typedef {Object} ConfigKeys
   * @property {string} key1 - Первый ключ конфигурации.
   * @property {string} key2 - Второй ключ конфигурации.
   */
  #featureName?: FeatureFlagConfigKeys;
  /**
   * Создает новый экземпляр конструктора.
   *
   * @param invert - Показывает инвертировать ли проверку на appFeatureFlag @Input
   * @param {ViewContainerRef} viewContainer - Ссылка на контейнер представления, используемую для доступа к контейнеру представления, где будет вставлен шаблон.
   * @param {TemplateRef<unknown>} templateRef - Ссылка на шаблон, используемая для доступа к шаблону, который будет вставлен.
   * @param {FeatureFlagService} featureFlagService - Сервис флагов функций, используемый для проверки статуса флагов функций.
   */
  constructor(
    private viewContainer: ViewContainerRef,
    private templateRef: TemplateRef<unknown>,
    private featureFlagService: FeatureFlagService,
    @Attribute('invert') public invert?: 'true',
  ) {}

  /**
   * Установите указанный флаг функции приложения.
   *
   * @param {FeatureFlagConfigKeys | undefined} featureName - Имя флага функции приложения для установки.
   * @returns {void}
   */
  @Input()
  set appFeatureFlag(featureName: FeatureFlagConfigKeys | undefined) {
    this.#featureName = featureName;
    this.updateView();
  }

  /**
   * Обновление видимости на основе статуса флага функции.
   * Если функция включена, создает встроенное представление из ссылки на шаблон.
   * В противном случае, очищает контейнер представления.
   *
   * @private
   * @returns {void}
   */
  updateView(): void {
    const value = this.#featureName
      ? this.invert
        ? !this.featureFlagService.isFeatureOn(this.#featureName)
        : this.featureFlagService.isFeatureOn(this.#featureName)
      : false;

    if (value) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }
}
